import React from 'react'
import ReactGA from 'react-ga'
import { useStaticQuery, graphql } from 'gatsby'

import AniLink from 'gatsby-plugin-transition-link/AniLink'

import PostItem from 'components/PostItem'
import AniLinkButton from 'components/AniLinkButton'
import { Posts as PostsStyled, Nav, Title } from './styles'

const trackClickAllPosts = () => {
  ReactGA.event({
    category: 'Blog',
    action: 'click',
    label: `Blog - Click to view all posts`,
  })
}

const PostsQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(locale: "pt-br", formatString: "DD MMM[,] YYYY")
            description
            title
            tags
          }
          timeToRead
        }
      }
    }
  }
`

const Posts = () => {
  const allPosts = useStaticQuery(PostsQuery)
  const list = allPosts.allMarkdownRemark.edges

  return (
    <PostsStyled>
      <Title>Espero que goste do conteúdo aqui no meu blog.</Title>
      <Nav>
        {list.map(({ node }, i) => (
          <PostItem
            key={i}
            slug={node.fields.slug}
            date={node.frontmatter.date}
            title={node.frontmatter.title}
            description={node.frontmatter.description}
            tags={node.frontmatter.tags}
            timeToRead={node.timeToRead}
            postsPage={true}
          />
        ))}
      </Nav>
      <AniLinkButton to='/blog/' onClick={() => trackClickAllPosts()}>
        Ver todos os posts
      </AniLinkButton>
    </PostsStyled>
  )
}

export default Posts
