import React from 'react'
import { Link } from 'gatsby'

import Container from '@/components/Container'
import SEO from '@/components/Seo'
import Posts from '@/components/Posts'
import Author from '@/components/Author'

export default () => {
  return (
    <Container>
      <SEO title='Blog pessoal' />
      <section
        style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '70rem' }}
      >
        <Author />
        <Posts />
      </section>
    </Container>
  )
}
