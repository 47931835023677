import React from 'react'
import PropTypes from 'prop-types'

import Content from '@/components/Content'

import { AvatarImage } from './styles'

const Author = () => {
  return (
    <Content>
      <div itemScope itemType='http://schema.org/Person'>
        <>
          <AvatarImage src='/icons/icon-512x512.png' />
          <h1>Fala galera, tudo bem com vocês?</h1>

          <p>
            Eu sou o <span itemProp='name'>Allison Verdam</span>. Sou{' '}
            <span itemProp='jobTitle'>Desenvolvedor FullStack</span> e{' '}
            apaixonado por desenvolvimento de software. Acredito que utilizando
            a tecnologia a gente consegue melhorar o mundo.
          </p>

          <p>
            Atualmente estou trabalhando na{' '}
            <a
              href='http://www.intelie.com.br/'
              target='_blank'
              rel='noopener noreferrer'
              title='Ver'
              itemProp='affiliation'
            >
              Intelie
            </a>
            . Sou criador da{' '}
            <a
              href='https://play.google.com/store/apps/developer?id=Suricatos+Tech'
              target='_blank'
              rel='noopener noreferrer'
              title='Ver'
              itemProp='owns'
            >
              Suricatos Tech
            </a>
            {'. '}
            Também tenho uns projetos legais no meu{' '}
            <a
              href='https://github.com/allisonverdam'
              target='_blank'
              rel='noopener noreferrer'
              title='Ver'
              itemProp='owns'
            >
              Github
            </a>
          </p>
        </>
        <hr />
      </div>
    </Content>
  )
}

export default Author
