import styled from 'styled-components'

import { Space, Heading2 } from 'utils/theme'

export const Posts = styled.section`
  display: block;

  &:not(:first-child) {
    margin-top: ${Space.xlg};
  }
`

export const Title = styled.h1`
  ${Heading2}

  &:not(:last-child) {
    margin-bottom: ${Space.sm};
  }
`

export const Nav = styled.nav`
  &:not(:last-child) {
    margin-bottom: ${Space.default};
  }
`
